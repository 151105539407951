






































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import ConfigSelector from './ConfigSelector.vue';
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';

import { Output } from '@/models/data/models';

@Component({
  components: {
    ConfigSelector,
  },
  data() {
    return {
      Output,
    };
  },
  mixins: [BeforeLeaveGuard],
})
export default class OutputForm extends Vue {
  @Prop({ required: true }) id: string;

  plugin_id = '';
  validity = '';
  validityClass = '';

  onLoaded(output: Output) {
    this.plugin_id = output.plugin_id;
    this.validity = output.config_valid ? 'valid' : 'invalid';
    this.validityClass = output.config_valid ? 'is-success' : 'is-danger';
  }

  beforeSaveHook(output: Output) {
    if (this.id === '0') {
      output.stream = this.$routerHandler.query('')['stream'];
      output.plugin_id = this.$routerHandler.query('')['output_plugin_id'];
    }
  }

  get filter() {
    return {
      application: this.$store.getters['global/application']
        ? this.$store.getters['global/application'].id
        : 'unknown', // explicitly set to 'unknown' to not show anything
    };
  }
}
